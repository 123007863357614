import React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from '@leshen/ui'
import siteTheme from '../../theme'

const Wrapper = ({ children }) => {
  return <ThemeProvider theme={siteTheme}>{children}</ThemeProvider>
}

Wrapper.defaultProps = {
  theme: {},
}

Wrapper.propTypes = {
  theme: PropTypes.object, // eslint-disable-line
  children: PropTypes.node.isRequired,
}

export default Wrapper
